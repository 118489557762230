import axios from 'axios';
import AuthService from "../views/authentication/AuthService";

const axiosInstance = axios.create({
    baseURL: 'https://admin.ihandel.in/api/v1/',
    timeout: 40000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Token': AuthService.getSessionToken() || ''
    },
});

axiosInstance.interceptors.request.use(config => {
    return config;
}, error => {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(response => {
    if (response.status >= 200 && response.status < 500) {
        return response;
    } else {
        throw new Error(`Request failed with status ${response.status}`);
    }
}, error => {
    return Promise.reject(error);
});

export default axiosInstance;
