import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import AuthService from '../views/authentication/AuthService';
import axiosInstance from "../components/axiosConfig";
import Swal from "sweetalert2";
import AllVouchersList from "../views/dashboard/vouchers/AllVouchersList";

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

/* ****Pages***** */
const Landing = Loadable(lazy(() => import('../views/landing-page/Landing')))
const About = Loadable(lazy(() => import('../views/landing-page/About')))
const Policy = Loadable(lazy(() => import('../views/landing-page/Policy')))
const Terms = Loadable(lazy(() => import('../views/landing-page/Terms')))
const ExchangePolicy = Loadable(lazy(() => import('../views/landing-page/ExchangePolicy')))
const RefundPolicy = Loadable(lazy(() => import('../views/landing-page/RefundPolicy')))

const Dashboard = Loadable(lazy(() => import('../views/dashboard/Dashboard')))
const SamplePage = Loadable(lazy(() => import('../views/sample-page/SamplePage')))
const Icons = Loadable(lazy(() => import('../views/icons/Icons')))
const TypographyPage = Loadable(lazy(() => import('../views/utilities/TypographyPage')))
const Shadow = Loadable(lazy(() => import('../views/utilities/Shadow')))
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Register = Loadable(lazy(() => import('../views/authentication/Register')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const Verify = Loadable(lazy(() => import('../views/authentication/Verify')));
const Aeps = Loadable(lazy(() => import('../views/dashboard/aeps/Aeps')));
const AadhaarDeposit = Loadable(lazy(() => import('../views/dashboard/aeps/AadhaarDeposit')));
const Payout = Loadable(lazy(() => import('../views/dashboard/payout/Payout')));
const Recharge = Loadable(lazy(() => import('../views/dashboard/bbps/Recharge')));
const BillPayments = Loadable(lazy(() => import('../views/dashboard/bbps/ExploreBillers')));
const BillerDetail = Loadable(lazy(() => import('../views/dashboard/bbps/BillerDetail')));
const BuyVouchers = Loadable(lazy(() => import('../views/dashboard/vouchers/BuyVouchers')));
const KycDetails = Loadable(lazy(() => import('../views/dashboard/kyc/KycDetails')));
const CommissionSlab = Loadable(lazy(() => import('../views/dashboard/commission/CommissionSlab')));
const RechargeReport = Loadable(lazy(() => import('../views/reports/Recharge')));
const AepsReport = Loadable(lazy(() => import('../views/reports/Aeps')));
const WalletReport = Loadable(lazy(() => import('../views/reports/Wallet')));
const BbpsReport = Loadable(lazy(() => import('../views/reports/Bbps')));
const BrandVouchersReport = Loadable(lazy(() => import('../views/reports/BrandVouchers')));
const PayoutReport = Loadable(lazy(() => import('../views/reports/Payout')));

const ProtectedRoute = ({element, path}) => {
    return AuthService.isAuthenticated() ? (
        element
    ) : (
        <Navigate to="/auth/login" replace state={{from: path}}/>
    );
};
const FreeRoute = ({element}) => {
    return AuthService.isAuthenticated() ? (
        <Navigate to="/dashboard" replace/>
    ) : (
        element
    );
};

const AepsRoute = ({element}) => {
    return check2faLogin() ? (
        element
    ) : (
        Swal.fire("Error!", "2Fa Login Not Completed.", "error"),
            <Navigate to="/dashboard" replace/>
    );
};

const check2faLogin = async () => {
    try {
        const response = await axiosInstance.get("aeps/is2FaVerified");
        if (response.data) {
            return response.data.status;
        }
    } catch (error) {
        return false;
    }
    return false;
}

const Router = [
    {
        path: '/', element: <BlankLayout/>, children: [
            {path: '/', exact: true, element: <Landing/>},
            {path: '/about', exact: true, element: <About/>},
            {path: '/policy', exact: true, element: <Policy/>},
            {path: '/terms', exact: true, element: <Terms/>},
            {path: '/shipping-policy', exact: true, element: <ExchangePolicy/>},
            {path: '/refund-policy', exact: true, element: <RefundPolicy/>},
        ]
    },
    {
        path: '/',
        element: <FullLayout/>,
        children: [
            {path: '/dashboard', exact: true, element: <ProtectedRoute element={<Dashboard/>} path="/dashboard"/>},
            {path: '/sample-page', exact: true, element: <ProtectedRoute element={<SamplePage/>} path="/sample-page"/>},
            {
                path: '/dashboard/aeps',
                exact: true,
                element: <ProtectedRoute element={<AepsRoute element={<Aeps/>}/>} path="/dashboard/aeps"/>
            },
            {
                path: '/dashboard/payout',
                exact: true,
                element: <ProtectedRoute element={<Payout/>} path="/dashboard/payout"/>
            },
            {
                path: '/dashboard/recharge',
                exact: true,
                element: <ProtectedRoute element={<Recharge/>} path="/dashboard/recharge"/>
            },
            {
                path: '/dashboard/kyc',
                exact: true,
                element: <ProtectedRoute element={<KycDetails/>} path="/dashboard/recharge"/>
            },
            {
                path: '/dashboard/bbps/:id/:categoryName',
                exact: true,
                element: <ProtectedRoute element={<BillPayments/>} path="/dashboard/bbps/:id/:categoryName"/>
            },
            {
                path: '/dashboard/bbps/:id/:billerName/:billerId',
                exact: true,
                element: <ProtectedRoute element={<BillerDetail/>} path="/dashboard/bbps/:id/:billerName/:billerId"/>
            },
            {
                path: '/dashboard/vouchers/:productKey/:productName/:minPrice/:maxPrice',
                exact: true,
                element: <ProtectedRoute element={<BuyVouchers/>}
                                         path="/dashboard/vouchers/:productKey/:productName/:minPrice/:maxPrice"/>
            },
            {
                path: '/dashboard/vouchers/list',
                exact: true,
                element: <ProtectedRoute element={<AllVouchersList/>} path="/dashboard/vouchers/list"/>
            },
            {
                path: '/dashboard/aadhaarDeposit',
                exact: true,
                element: <ProtectedRoute element={<AadhaarDeposit/>} path="/dashboard/AadhaarDeposit"/>
            },
            {
                path: '/dashboard/commission',
                exact: true,
                element: <ProtectedRoute element={<CommissionSlab/>} path="/dashboard/commission"/>
            },
            {
                path: '/dashboard/reports/recharge',
                exact: true,
                element: <ProtectedRoute element={<RechargeReport/>} path="/dashboard/reports/recharge"/>
            },
            {
                path: '/dashboard/reports/aeps',
                exact: true,
                element: <ProtectedRoute element={<AepsReport/>} path="/dashboard/reports/aeps"/>
            },
            {
                path: '/dashboard/reports/wallet',
                exact: true,
                element: <ProtectedRoute element={<WalletReport/>} path="/dashboard/reports/wallet"/>
            },
            {
                path: '/dashboard/reports/bbps',
                exact: true,
                element: <ProtectedRoute element={<BbpsReport/>} path="/dashboard/reports/bbps"/>
            },
            {
                path: '/dashboard/reports/brand',
                exact: true,
                element: <ProtectedRoute element={<BrandVouchersReport/>} path="/dashboard/reports/brand"/>
            },
            {
                path: '/dashboard/reports/payout',
                exact: true,
                element: <ProtectedRoute element={<PayoutReport/>} path="/dashboard/reports/payout"/>
            },
            {path: '*', element: <Navigate to="/auth/404"/>},
        ],
    },
    {
        path: '/auth',
        element: <BlankLayout/>,
        children: [
            {path: '404', element: <Error/>},
            {path: '/auth/register', element: <Register/>},
            {path: '/auth/login', element: <FreeRoute element={<Login/>}/>}, // Use FreeRoute here
            {path: '/auth/verify', element: <FreeRoute element={<Verify/>}/>}, // Use FreeRoute here
            {path: '*', element: <Navigate to="/auth/404"/>},
        ],
    },

];

export default Router;
